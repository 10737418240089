import React, { useContext, useEffect, useState } from "react";
import AuthorizeContext from "../../context/common/AuthorizeContext";
import OperationContext from "../../context/common/OperationContext";
import LoadingBar from 'react-top-loading-bar';
import 'tippy.js/dist/tippy.css';
import SidebarAdmin from "./SidebarAdmin";
import Select from 'react-select';
import TriggerToastify from "../../components/common/TriggerToastify";
import NavbarAdmin from "./NavbarAdmin";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import makeAnimated from 'react-select/animated';
import SportsContext from "../../context/sports/SportsContext";

const AdminProfile = () => {

    const { authorizeAdminUser } = useContext(AuthorizeContext);
    const { sidebarOpen } = useContext(OperationContext);
    const websiteName = process.env.REACT_APP_WEBSITE_NAME;
    const [progressTopBar, setProgressTopBar] = useState(0);
    const [errorMessageTeamMatch, setErrorMessageTeamMatch] = useState('');
    const userToken = localStorage.getItem('userToken');
    const [otherDetails, setOtherDetails] = useState('');
    const [businessHours, setBusinessHours] = useState("");
    const [openingHours, setOpeningHours] = useState("");
    const [sportsDetailsList, setSportsDetailsList] = useState("");
    const [slotHours, setSlotHours] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [facebookLink, setFacebookLink] = useState("");
    const [instagramLink, setInstagramLink] = useState("");
    const [twitterLink, setTwitterLink] = useState("");
    const [locationLink, setLocationLink] = useState("");
    const [area, setArea] = useState("");
    const [adminPanel, setAdminPanel] = useState("");
    const [errorSport,setErrorSport] = useState('');
    const animatedComponents = makeAnimated();
    const {getSports, sportsDetails, errorMessageSports,successMessageSports,showPopup,popup,hidePopup,sendAddNewSports}  = useContext(SportsContext);
    var hoursAdd = [];
    for(var inc=1;inc<=24;inc++){
        hoursAdd.push({value:inc, label:inc+" Hours"});
    }

    var hoursOpening = [];
    var dayTime = "AM";
    for(var inc=1;inc<=24;inc++){
        if(inc>=12){dayTime="PM";}
        hoursOpening.push({value:inc, label:inc+" "+dayTime});
    }

    //sidebar open close
    if (sidebarOpen === true) { var openSidebar = "toggled" } else { openSidebar = "" }

    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        authorizeAdminUser();//Check user authorize
        loadProfile();
        getSports();//fetch sports
        document.title = "Admin Profile | " + websiteName;
    }, [websiteName])

    
    //Submit challenge
    const handleSubmit = async (event) => {//submit form with form data
        event.preventDefault();
        const data = new FormData(event.target);
        setProgressTopBar(30)

        const buisness_hoursForm = data.get('buisness_hours').trim();
        const slotForm = data.get('slot').trim();
        const detailsForm = otherDetails;
        const area_hoursForm = data.get('area').trim();
        const name_hoursForm = data.get('name').trim();
        const opening_hourForm = data.get('opening_hour').trim();
        const facebookForm = data.get('facebook');
        const instagramForm = data.get('instagram');
        const twitterForm = data.get('twitter');
        const locationForm = data.get('location');
        const errorSubmit = [];

        if (buisness_hoursForm === "" || buisness_hoursForm === undefined) {
            setErrorMessageTeamMatch("Please select business hours")
            errorSubmit.push(1)
        }

        if (slotForm === "" || slotForm === undefined) {
            setErrorMessageTeamMatch("Please select slot")
            errorSubmit.push(1)
        }

        if (area_hoursForm === "" || area_hoursForm === undefined) {
            setErrorMessageTeamMatch("Please select area")
            errorSubmit.push(1)
        }

        if (name_hoursForm === "" || name_hoursForm === undefined) {
            setErrorMessageTeamMatch("Please select name")
            errorSubmit.push(1)
        }

        if (opening_hourForm === "" || opening_hourForm === undefined) {
            setErrorMessageTeamMatch("Please select opening hour")
            errorSubmit.push(1)
        }

        if(selectedOptionsSportId === "" || selectedOptionsSportId === undefined){
            setErrorMessageTeamMatch("Please select atleast 1 sports")
            errorSubmit.push(1)
        }
        
        //Submit form
        if (errorSubmit !== undefined && errorSubmit.length < 1) {

            var formDataJson = {
                'business_hours':buisness_hoursForm,
                'slot':slotForm,
                'details':detailsForm,
                'name':name_hoursForm,
                'area':area_hoursForm,
                'opening_hour':opening_hourForm,
                'sport_id':selectedOptionsSportId,
                'facebook':facebookForm,
                'instagram':instagramForm,
                'twitter':twitterForm,
                'location':locationForm,
            }
            
            var formBody = [];
            for (var property in formDataJson) {
                var encodedKeySignup = encodeURIComponent(property);
                var encodedValueSignup = encodeURIComponent(formDataJson[property]);
                formBody.push(encodedKeySignup + "=" + encodedValueSignup);
            }
            formBody = formBody.join("&");

            const urlkey = process.env.REACT_APP_NODE_BASE_URL;
            const teamURL = urlkey+'search/add_admin_details';
            const response = await fetch(teamURL,{
                method: 'POST',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + userToken
                },
                body: formBody
            });
            const json = await response.json();
            if(json !== "" && json !== undefined){
                if(json.status){
                    var result = json.result;
                    setProgressTopBar(100)
                    TriggerToastify(json.message, "success");
                }else if(json.status === false){
                    TriggerToastify(json.message, "error");
                }
            }
        } else {
            setProgressTopBar(100)
        }
    }

    //Submit challenge
    const loadProfile = async () => {//submit form with form data
        setProgressTopBar(30)

        const urlkey = process.env.REACT_APP_NODE_BASE_URL;
        const teamURL = urlkey+'search/admin_details';
        const response = await fetch(teamURL,{
            method: 'POST',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + userToken
            }
        });
        const json = await response.json();
        if(json !== "" && json !== undefined){
            setProgressTopBar(100);
            if(json.status){
                var result = json.result;
                setBusinessHours(result[0].business_hours);
                setSlotHours(result[0].slot);
                setAdminPanel(result[0].details);
                setBusinessName(result[0].name);
                setFacebookLink(result[0].facebook);
                setInstagramLink(result[0].instagram);
                setTwitterLink(result[0].twitter);
                setLocationLink(result[0].location);
                setArea(result[0].area);
                setOpeningHours(result[0].opening_hour);
                const sportsarray = [];//sports array
                for (let inc = 0; inc < json.sports.length; inc++) {
                    sportsarray.push({value:json.sports[inc].id, label:json.sports[inc].name})
                }
                setSportsDetailsList(sportsarray);
                TriggerToastify(json.message, "success");
            }else if(json.status === false){
                TriggerToastify(json.message, "error");
            }
        }
    }

    //Sports options
    const [selectedOptionsSport, setSelectedOptionsSport] = useState([]);
    const [selectedOptionsSportId, setSelectedOptionsSportId] = useState([]);
    const sportChange = (sportOptions) => {
        var sporstIds = "";
        for(inc=0;inc<sportOptions.length;inc++){
            sporstIds += sportOptions[inc].value+",";
            setSelectedOptionsSportId(sporstIds);
        }
        setSelectedOptionsSport(sportOptions);
        setErrorSport('')
    };

    //Add New Sports 
    const [sportsNew, setSportsNew] = useState("");
    const [errorSports, setErrorSports] = useState("");
    const newSportsChange = (event) => {
        setSportsNew(event.target.value);
        setErrorSports("");
    }
    
    //Submit forgot password
    const handleSubmitSports = () => {//submit form with form data
        setProgressTopBar(30)
        const errorSubmit = [];

        if (sportsNew === "" || sportsNew === undefined) {
            setErrorSports("Please enter new sports")
            errorSubmit.push(1)
        }

        //Submit form
        if (errorSubmit !== undefined && errorSubmit.length < 1) {

            var formData = {
                'sport': sportsNew
            }
            sendAddNewSports(formData);//update details by API
            setProgressTopBar(100);
        } else {
            setProgressTopBar(100)
        }
    }

    return (
        <>
            <div className="container-fluid noMargin noPadding">
                <LoadingBar color='#f11946' height={2} shadow={true} progress={progressTopBar} onLoaderFinished={() => setProgressTopBar(0)} />
                <NavbarAdmin />
                <div id="wrapper" className={openSidebar}>
                    <div id="page-content-wrapper noMargin noPadding ">
                        <div className="container-fluid noMargin noPadding ">
                            <br /><br />
                            <div className="containDetails">
                                <SidebarAdmin />
                                <div id="page-content-wrapper noMargin noPadding">
                                    <div className="addPlayer">
                                    <br></br>
                                        <div className="container" align="center">
                                            <h3>Profile Details</h3>
                                            <form action="#" className="form" onSubmit={handleSubmit}>
                                                <div className="row noMargin">
                                                    <div className="col-sm-6 col-xs-6 col-lg-6 col-md-6">
                                                        <label><b>Name</b></label>
                                                        <input type="text" className="form-control" name="name" placeholder="Name" defaultValue={businessName} /><br></br>
                                                    </div>
                                                    <div className="col-sm-6 col-xs-6 col-lg-6 col-md-6">
                                                        <label><b>Area</b></label>
                                                        <input type="text" className="form-control" name="area" placeholder="Area" defaultValue={area} /><br></br>
                                                    </div>
                                                </div>
                                                <label><b>Other Details</b></label>
                                                <CKEditor
                                                    editor={ ClassicEditor }
                                                    data={adminPanel}
                                                    name="details" 
                                                    config={{placeholder: "Add other details (Like match venue, timing, etc)"}} 
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        setOtherDetails(data)
                                                    } }
                                                    onReady={ editor => {
                                                        setOtherDetails(adminPanel)
                                                    } }
                                                /><br></br>
                                                <div className="row noMargin">
                                                    <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                                        <label><b>Opening Hours</b></label>
                                                        {openingHours != null && openingHours != "" ? (<Select name="opening_hour" options={hoursOpening} defaultValue={{value:openingHours, label: openingHours+" "+(openingHours >= 12 ? "PM" : "AM")}} placeholder="Opening Hour" />) : ""}
                                                        {openingHours == null ? <Select name="opening_hour" options={hoursOpening} placeholder="Opening Hour" /> : ""}
                                                        <br></br>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                                        <label><b>Business Hours</b></label>
                                                        {businessHours != "" ? (<Select name="buisness_hours" options={hoursAdd} defaultValue={{value:businessHours, label: businessHours+" Hours"}} placeholder="Club/Arena/Playground/etc Hours" />) : ""}
                                                        {businessHours == "" ? <Select name="buisness_hours" options={hoursAdd} placeholder="Club/Arena/Playground/etc Hours" /> : ""}
                                                        <br></br>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                                        <label><b>Slot Hours</b></label>
                                                        {slotHours != "" ? (<Select name="slot" options={hoursAdd} defaultValue={{value:slotHours, label: slotHours+" Hours"}} placeholder="Slot Hours" />) : ""}
                                                        {slotHours == "" ? (<Select name="slot" options={hoursAdd} placeholder="Slot Hours" />) : ""}
                                                        <br></br>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="input-box address">
                                                            <div className="select-box">
                                                            <label><b>Sports</b></label>
                                                            {sportsDetailsList != "" ? <Select closeMenuOnSelect={false} components={animatedComponents} isMulti options={sportsDetails} defaultValue={sportsDetailsList} onChange={sportChange} placeholder="Sports" /> : ""}
                                                            {sportsDetailsList == "" ? <Select closeMenuOnSelect={false} components={animatedComponents} isMulti options={sportsDetails} value={selectedOptionsSport} onChange={sportChange} placeholder="Sports" /> : ""}
                                                                <div className="addSports" align="right"><span className="addNewSportsLink" onClick={showPopup}>Add Sports</span></div>
                                                                {!!errorSport ? (<span className="text text-danger">{errorSport}</span>) : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row noMargin">
                                                        <div className="col-sm-3 col-xs-12 col-lg-3 col-md-6">
                                                            <label><b>Location link</b></label>
                                                            <input type="text" className="form-control" name="location" placeholder="Location link" defaultValue={locationLink} /><br></br>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-12 col-lg-3 col-md-6">
                                                            <label><b>Facebook link</b></label>
                                                            <input type="text" className="form-control" name="facebook" placeholder="Facebook link" defaultValue={facebookLink} /><br></br>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-12 col-lg-3 col-md-6">
                                                            <label><b>Instagram link</b></label>
                                                            <input type="text" className="form-control" name="instagram" placeholder="Instagram link" defaultValue={instagramLink} /><br></br>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-12 col-lg-3 col-md-6">
                                                            <label><b>Twitter link</b></label>
                                                            <input type="text" className="form-control" name="twitter" placeholder="Twitter link" defaultValue={twitterLink} /><br></br>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!!errorMessageTeamMatch ? (<div><span className="text text-danger">{errorMessageTeamMatch}</span></div>) : ""}
                                                <button className="btn btn-success">Save</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Popup Start */}
                        <div id="popupAddSports" className="overlay" style={popup}>
                            <div className="popup popupAddSports">
                                <h4>Add Sports</h4>
                                <span className="close" onClick={hidePopup}>&times;</span>
                                <div className="content">
                                    <input type="text" name="new_sports" className="form-control" onChange={newSportsChange} placeholder="Add new sports" required />
                                    {!!errorMessageSports ? (<div className="alert alert-danger">{errorMessageSports}</div>) : ""}
                                    {!!successMessageSports ? (<div className="alert alert-success">{successMessageSports}</div>) : ""}
                                    {!!errorSports ? (<div className="alert alert-danger">{errorSports}</div>) : ""}
                                    <button className="btn btn-primary" onClick={handleSubmitSports}>Add</button>
                                </div>
                            </div>
                        </div>
                        {/* Popup Ends */}

                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminProfile;