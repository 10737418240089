import React, { useContext, useEffect, useState } from "react";
import AuthorizeContext from "../../context/common/AuthorizeContext";
import OperationContext from "../../context/common/OperationContext";
import LoadingBar from 'react-top-loading-bar';
import 'tippy.js/dist/tippy.css';
import SidebarAdmin from "./SidebarAdmin";
import capitalizeWords from '../common/CapitalizeWords';
import dateFormat from 'dateformat';
import TriggerToastify from "../common/TriggerToastify";
import NavbarAdmin from "./NavbarAdmin";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SlotBookings = () => {

    const { authorizeAdminUser } = useContext(AuthorizeContext);
    const { sidebarOpen } = useContext(OperationContext);
    const websiteName = process.env.REACT_APP_WEBSITE_NAME;
    const [progressTopBar, setProgressTopBar] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const userToken = localStorage.getItem('userToken');
    const [slotBooking, setSlotBooking] = useState('');
    const [otherDetails, setOtherDetails] = useState('');
    

    //sidebar open close
    if (sidebarOpen === true) { var openSidebar = "toggled" } else { openSidebar = "" }

    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        authorizeAdminUser();//Check user authorize
        loadSlotBooking();
        document.title = "Slot Booking | " + websiteName;
    }, [websiteName])

    
    const loadSlotBooking = async () => {
        const urlkey = process.env.REACT_APP_NODE_BASE_URL;
        const loginURL = urlkey+'search/admin_slot_booking_list';
        const response = await fetch(loginURL,{
            method: 'POST',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + userToken
            },
        });
        const json = await response.json();
        if(json !== "" && json !== undefined){
            if(json.status === true){
                if(json.total > 0){
                    const resultTeams = json.result;
                    setSlotBooking(resultTeams);
                }
            }
        }
    }

    const accpet = (challenge_id,status) => {
        statusBooking({"challenge_id":challenge_id,"status":status});
        setTimeout(function(){loadSlotBooking();},1000);
    }

    const statusBooking = async (data) => {
        var formBody = [];
        for (var property in data) {
            var encodedKeySignup = encodeURIComponent(property);
            var encodedValueSignup = encodeURIComponent(data[property]);
            formBody.push(encodedKeySignup + "=" + encodedValueSignup);
        }
        formBody = formBody.join("&");
        const urlkey = process.env.REACT_APP_NODE_BASE_URL;
        const loginURL = urlkey+'search/accept_slot_booking';
        const response = await fetch(loginURL,{
            method: 'POST',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + userToken
            },
            body: formBody
        });
        const json = await response.json();
        if(json !== "" && json !== undefined){
            if(json.status === true){
                TriggerToastify(json.message, "success");
            }else{
                TriggerToastify(json.message, "error");
            }
        }
    }

    
    //Submit challenge
    const handleSubmitSave = async (event) => {//submit form with form data
        setErrorMessage('');
        event.preventDefault();
        const data = new FormData(event.target);
        setProgressTopBar(30)

        const challenge_idForm = data.get('challenge_id').trim();
        const linkForm = data.get('link').trim();
        const detailsForm = data.get('details');
        const errorSubmit = [];

        if (challenge_idForm === "" || challenge_idForm === undefined) {
            setErrorMessage("Please enter match id")
            errorSubmit.push(1)
        }
        
        if (linkForm === "" || linkForm === undefined) {
            setErrorMessage("Please select link")
            errorSubmit.push(1)
        }
        
        //Submit form
        if (errorSubmit !== undefined && errorSubmit.length < 1) {

            var formDataJson = {
                'challenge_id': challenge_idForm,
                'link':linkForm,
                'details':detailsForm
            }
            
            var formBody = [];
            for (var property in formDataJson) {
                var encodedKeySignup = encodeURIComponent(property);
                var encodedValueSignup = encodeURIComponent(formDataJson[property]);
                formBody.push(encodedKeySignup + "=" + encodedValueSignup);
            }
            formBody = formBody.join("&");

            const urlkey = process.env.REACT_APP_NODE_BASE_URL;
            const loginURL = urlkey+'search/add_mobile_details';
            const response = await fetch(loginURL,{
                method: 'POST',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + userToken
                },
                body: formBody
            });
            const json = await response.json();
            if(json !== "" && json !== undefined){
                if(json.status){
                    setProgressTopBar(100)
                    TriggerToastify(json.message, "success");
                }else if(json.status === false){
                    TriggerToastify(json.message, "error");
                }
            }
        } else {
            setProgressTopBar(100)
        }
    }

    return (
        <>
            <div className="container-fluid noMargin noPadding">
                <LoadingBar color='#f11946' height={2} shadow={true} progress={progressTopBar} onLoaderFinished={() => setProgressTopBar(0)} />
                <NavbarAdmin />
                <div id="wrapper" className={openSidebar}>
                    <div id="page-content-wrapper noMargin noPadding ">
                        <div className="container-fluid noMargin noPadding ">
                            <br /><br />
                            <div className="containDetails">
                                <SidebarAdmin />
                                <div id="page-content-wrapper noMargin noPadding">
                                    <br></br>
                                    <div className="container" align="center">
                                        <h3>Slot Booking</h3>
                                            <div className="row nomargin">
                                            {
                                                slotBooking.length > 0 ? slotBooking.map((team, i) => {
                                                    var mySlot = team.slot_time.split("-");
                                                    var daytimeOne = "PM";
                                                    var daytimeTwo = "PM";
                                                    if(mySlot[0]<12){daytimeOne = "AM";}
                                                    if(mySlot[1]<12){daytimeTwo = "AM";}
                                                    var newSlotTime = mySlot[0] + daytimeOne + "-" + mySlot[1] + daytimeTwo;

                                                    var displayName = "";
                                                    {team.admin_status == 1 ? displayName = "block" :  displayName = "none"}
                                                    return (<div className="col-sm-4 col-xs-4 col-md-4 col-lg-4" key={i}>
                                                                <div className="card-result slotBooking">
                                                                    <div className="pointerScore">{team.sport_name}</div>
                                                                    <div className="scoreCard">
                                                                        <div className="nameText fontStyle">{capitalizeWords(team.teamname)}</div>
                                                                        <div className="scoreAlign"><img src="vsIcon.png" className="img-responsive vsScore" alt="vs icon" /></div>
                                                                        <div className="nameText fontStyle">{capitalizeWords(team.opponent_name)}</div>
                                                                    </div>
                                                                    <br></br>
                                                                    <h6>
                                                                        Slot Date: {team.slot_date}
                                                                    </h6>
                                                                    <h6>
                                                                        Slot Time: {newSlotTime}
                                                                    </h6>
                                                                    <div>
                                                                    {!!errorMessage ? (<div><span className="text text-danger">{errorMessage}</span></div>) : ""}
                                                                    {team.admin_status == 0 && team.admin_status != undefined ? <button className="btn btn-success btn-sm" onClick={() => accpet(team.challenge_id,1)}>Accept</button> : ""}&nbsp;&nbsp;
                                                                    {team.admin_status == 0 && team.admin_status != undefined ? <button className="btn btn-danger btn-sm" onClick={() => accpet(team.challenge_id,2)}>Reject</button> : ""}
                                                                        <div className="bookingStatus">
                                                                        {team.admin_status != 0 ? "Booking Status:" : ""}
                                                                        {team.admin_status == 1 ? (<span className="text text-success">Accepted</span>): ""}
                                                                        {team.admin_status == 2 ? (<span className="text text-danger">Rejected</span>): ""}
                                                                        </div>
                                                                        <br></br>
                                                                        <div style={{display:displayName}}>
                                                                            <div align="center" className="pointerResult">Live Link</div>
                                                                            <div><a href={"https://team.playtoconquer.com/live_match?challenge_id="+team.challenge_id}>https://team.playtoconquer.com/live_match?challenge_id={team.challenge_id}</a></div>
                                                                            <br></br>
                                                                            <form action="#" className="form" onSubmit={handleSubmitSave}>
                                                                                <input type="hidden" name="details" value={otherDetails} />
                                                                                <div align="center" className="pointerResult">Match Details</div>
                                                                                <CKEditor
                                                                                    editor={ ClassicEditor }
                                                                                    data={team.match_details}
                                                                                    config={{placeholder: "Add other details (Like match venue, timing, etc)"}} 
                                                                                    onChange={ ( event, editor ) => {
                                                                                        const data = editor.getData();
                                                                                        setOtherDetails(data)
                                                                                    } }
                                                                                    onReady={ editor => {
                                                                                        setOtherDetails(team.match_details)
                                                                                    } }
                                                                                /><br></br>
                                                                                <div align="center" className="pointerResult">Stream Details</div>
                                                                                <div className="scoreCard">
                                                                                    <input type="hidden" name="challenge_id" value={team.challenge_id} />
                                                                                    <input type="text" name="link" placeholder="Stram link" defaultValue={team.link} className="form-control" />
                                                                                </div>
                                                                                <div><button className="btn btn-success">Save</button></div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                }) : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SlotBookings;